$(document).ready(function(){
    hasSearchQuery() && removeMockDisplay();
    setSessionDownloadInfo();
    addDownloadModalLink();
    showDownloadLink();
    hideDownloadLink();
})

function removeMockDisplay(){
    $(".mock-resource-center").hide();
    $(".search-wrapper").removeClass("init");

}
function hasSearchQuery(){
    let result= window.location.href.includes("searchQuery");
    return result;
}

function setSessionDownloadInfo() {
    if (sessionStorage.acceptedAdvertisingPromotionLicense) {
        $(".download-toggle").each(function () {
            var downloadLink = $(this).data("file-url");
            $(this).attr("href", downloadLink);
            $(this).removeAttr("data-toggle");
            $(this).removeAttr("data-target");
        })
    }
}

function attachShowResourceEvent($element) {
    let $resourceTables = $element;
    $resourceTables.each(function () {
        let $this = $(this);
        let $trigger = $(this).find(".document-trigger");
        let $resourceContent = $this.find("tr:not(:first-child)");
        $trigger.on("click", function (e) {
            $this.toggleClass("open");
            $resourceContent.toggleClass("display");
        })
    })
}

function addDownloadModalLink() {
    $(".download-toggle").click(function () {
        var downloadLink = $(this).data("file-url");
        $("#download-modal").find(".button.download-button").attr("href", downloadLink);
    });
}

function showDownloadLink() {
    $("input#term-agreement").click(function () {                       
        if ($(this).is(':checked')) {
            $(".download-link-container").fadeIn();
        }
        else {
            $(".download-link-container").hide();
        }
    });

    $(".download-link-container .button.download-button").click(function () {
        if (!localStorage.getItem("acceptedAdvertisingPromotionLicense")) {
            localStorage.setItem("acceptedAdvertisingPromotionLicense", true);
            $(".acceptance").hide();
            $(".affirmation").show();
        }
        if (!sessionStorage.acceptedAdvertisingPromotionLicense) {
            sessionStorage.acceptedAdvertisingPromotionLicense = true;
            setSessionDownloadInfo();
        }
        $(".modal-footer button").click();
    });

    if (localStorage.getItem("acceptedAdvertisingPromotionLicense")) {
        $(".acceptance").hide();
        $(".affirmation").show();
        $(".download-link-container").show();
    }
}

function hideDownloadLink() {
    $('#download-modal').on('hidden.bs.modal', function () {
        if (!localStorage.getItem("acceptedAdvertisingPromotionLicense")) {
            $(".download-link-container").hide();
            $("input#term-agreement").prop('checked', false);
        }
    })
}