$(document).ready(function () {
    const countryLegalAge = 18;

    let $ageVerify = $(".age-verification");
    let $submit = $ageVerify.find("#sumbitVerification");
    let $decline = $ageVerify.find("#declineVerification");
    let $month = $ageVerify.find("#month");
    let $day = $ageVerify.find("#day");
    let $year = $ageVerify.find("#year");
    let month, day, year, rememberMeForever;
    setTimeout(function () {
        document.querySelector('body').classList.remove('loading');    }, 1000);

    $(window).on("load", function () {
        (!alreadyValidated() && !inBackendSitefinity() && !isCookieNotice() && siteRequiresValidation()) ? redirectToValidateForm(): loadPage();
    });

    $submit.on("click", function () {
        mapValues();
        if (formIsValid()) {
            (isLegal()) ? storeAsValidUserAndRedirect() : displayInvalidMessage();
            TrackingConsentManager.updateUserConsent(true);
        } else {
            displayAsInvalid();
        }

    });
    $decline.on("click", function () {
        mapValues();
        if (formIsValid()) {
            (isLegal()) ? storeAsValidUserAndRedirect() : displayInvalidMessage();
            TrackingConsentManager.updateUserConsent(false);
        } else {
            displayAsInvalid();
        }

    });

    $ageVerify.find("input").on("keyup", function () {
        $ageVerify.find(".age-birthdate").removeClass("invalid");
    });

    $month.on('keyup', function (e) {
        if ($month.val().length == 2)
            $day.focus();
    });

   $day.on('keyup', function (e) {
        if ($day.val().length == 2)
            $year.focus();
   });

    $year.on('keyup', function (e) {
        if ($year.val().length == 4)
            $submit.focus();
    });

    function displayAsInvalid() {
        $ageVerify.find(".age-birthdate").addClass("invalid");
    }

    function loadPage() {
        $("body").removeClass("loading");
    }

    function storeAsValidUserAndRedirect() {
        rememberMeForever
            ?
            localStorage.setItem("isValidUser", true) :
            sessionStorage.setItem("isValidUser", true);
        redirectToWebSite();
    }

    function mapValues() {
        // countryLegalAge = $ageVerify.find("#country").val();
        month = $ageVerify.find("#month").val();
        day = $ageVerify.find("#day").val();
        year = $ageVerify.find("#year").val();
        rememberMeForever = $ageVerify.find("#rememberMe").is(":checked");
    }

    function birthDate() {
        return new Date(year, month-1, day);
    }

    function formIsValid() {
        let validMonth = new RegExp("^(0[1-9]|[1][012])$");
        let validDay = new RegExp("^(0[1-9]|[12][0-9]|3[01])$");
        let validYear = new RegExp("^((19|20)[0-9]{2})$");

        return validMonth.test(month) && validDay.test(day) && validYear.test(year);
    }

    function legalDate() {
        return moment().subtract(countryLegalAge, "years")._d;
    }

    function isLegal() {
        return legalDate().getTime() >= birthDate().getTime();
    }

    function alreadyValidated() {
        return (
            JSON.parse(sessionStorage.getItem("isValidUser")) ||
            JSON.parse(localStorage.getItem("isValidUser")) || alreadyOnValidatePage()
        );
    }
    function siteRequiresValidation() {
        if (isPlayingVideo()) return false;
        if (window.location.hostname.includes("au.")) return false;
        return true;
    }

    function isPlayingVideo() {
        return JSON.parse(sessionStorage.getItem("playingVideo"));
    }

    function alreadyOnValidatePage() {
        return window.location.pathname == "/age-verification";
    }

    function redirectToValidateForm() {
        storeAttemptedPathName();
        window.location.href = "/age-verification";
    }

    function storeAttemptedPathName() {
        let pathname = window.location.pathname + location.search;
        if (pathname === "//")
            pathname = "/";
        sessionStorage.setItem("attemptedPathName", pathname);
    }

    function displayInvalidMessage() {
        let $bounceAlert = $(".legal-warning");
        $bounceAlert.stop().bounceAlert(1500);
    }

    function redirectToWebSite() {
        let pathname = sessionStorage.getItem("attemptedPathName")
        if (pathname === null) {
            pathname = "/";
        }
        window.location.href = pathname;
    }

    function inBackendSitefinity() {
        return $(".sfPageEditor").length > 0;
    }

    function isCookieNotice() {
        return window.location.href.indexOf("cookie-notice") > -1
    }

    function resetSessionStorage() {
        sessionStorage.setItem("isValidUser", null);
        location.reload(true);
    }

    function resetLocalStorage() {
        localStorage.setItem("isValidUser", null);
        location.reload(true);
    }

})