$(document).ready(function () {
    let $gamesFilter = $(".games-filter");
    let $categoryNav = $gamesFilter.find(".categories-navigation");
    let $categoryNavTrigger = $categoryNav.find(".categories-nav-trigger");
    let $categoryTitles = $categoryNav.find(".category-titles");
    let $categoryTriggers = $gamesFilter.find(".categories-trigger");
    let $categories = $gamesFilter.find(".categories");
    let $categoriesDesktop = $gamesFilter.find(".categories-desktop-placeholder");


    attachNavTrigger();
    $(window).on("load resize", function () {
        renderCategoryPlacement();
    })

    function attachNavTrigger() {
        $categoryNavTrigger.on("click", function () {
            $categoryNav.toggleClass("active");
            $categoryTitles.slideToggle();
        })
    }

    function renderCategoryPlacement() {
        ($(window).width() < 992) ? renderMobileView(): renderDesktopView();
    }

    function renderMobileView() {
        $categoryTriggers.each(function () {
            let $content = $("#" + $(this).data("accordion"));
            $(this).parent().append($content);
        })
    }

    function renderDesktopView() {
        $categories.each(function () {
            $categoriesDesktop.append($(this));
        })
    }



})