$(document).ready(function () {
    const speed = 500;

    let $header = $("header");
    let $menuTrigger = $header.find(".nav-trigger");
    let $navigation = $header.find(".navigation");
    let $search = $header.find(".search");
    let $login = $header.find(".login");
    let $loginView = $login.find(".header-logged-out, .header-logged-in");
    let $inputWrapper = $search.find(".input-wrapper");

    let $dropdownTrigger = $header.find(".dropdown-trigger");
    let $subNav = $header.find(".sub-nav");
    let $loginTrigger = $header.find(".login-trigger");
    let $searchTrigger = $header.find(".search-trigger")    

    $menuTrigger.on("click", toggleMenuItems);

    $dropdownTrigger.click(function (e) {
        let $thisSubNav = $(this).siblings(".sub-nav");
        e.stopPropagation();
        $subNav.not($thisSubNav).removeClass("active");
        $thisSubNav.toggleClass("active");
        closeLogin();
        closeSearch();
    });
    $loginTrigger.click(function (e) {
        e.stopPropagation();
        $loginView.toggleClass("active");
        closeDropdowns();
        closeSearch();
    });
    $searchTrigger.click(function (e) {
        e.stopPropagation();
        $inputWrapper.toggleClass("active");
        $("ul.ui-autocomplete").removeAttr("style");
        closeDropdowns();
        closeLogin();
    });
    $(window).on("click", function () {
        closeDropdowns();
        closeLogin();
        closeSearch();
    })


    $(".button-wrapper, .input-wrapper").on("click", function (e) {
        e.stopPropagation();
    })

    function closeDropdowns() {
        $subNav.removeClass("active");
    }

    function closeLogin() {
        $loginView.removeClass("active");
    }

    function closeSearch() {
        $inputWrapper.removeClass("active");
        $("ul.ui-autocomplete").removeAttr("style");
    } 

    function toggleMenuItems() {
        $navigation.slideToggle(speed);
        $search.slideToggle(speed);
        $login.slideToggle(speed);
        //  $trigger.toggleClass("open");
    }


    document.addEventListener('keyup', function (event) {

        if (event.defaultPrevented) {
            return;
        }

        var key = event.key || event.keyCode;

        if (key === 'Escape' || key === 'Esc' || key === 27) {
            closeSearch();
        }
    });


   toggleLoginView();

    function toggleLoginView() {
        let $templateOnly = $(".template-only");
        let $signInTrigger = $templateOnly.find("#login");
        let $signOutTrigger = $templateOnly.find(".user-navigation").find("a");

        $signInTrigger.on("click", function () {
            $(".header-logged-in").show();
            $(".header-logged-out").hide();
        })
        $signOutTrigger.on("click", function(){
            $(".header-logged-in").hide();
            $(".header-logged-out").show();
        })
    }

})