$(document).ready(function () {
    sfDisplayAddons();
})

function sfDisplayAddons() {
    let $sfColsIns = $(".sf_colsIn");

    $sfColsIns.each(function () {
        addPlaceHolderTextToBar($(this));
    });

    function addPlaceHolderTextToBar($sfColsIn) {
        let $bar = $sfColsIn.find(".rdTitleBar").find("em");
        let placeholderText = " <span class='bar-placeholder-text'>[Placeholder :: " + $sfColsIn.data("placeholder-label") + "]</span>";
        $bar.find("span").remove();
        $bar.append(placeholderText);
    }
}