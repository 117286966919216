$(document).ready(function () {
    let speed = 250;
    let $accordions = $(".accordion");
    let $accordionTriggers = $(".accordion-trigger");
    let $accordionContents = $(".accordion-content");

    $accordionTriggers.each(function () {
        let $this = $(this);
        $this.on("click", function () {
            let $content = $(("#" + $this.data("accordion")));
            let $thisAccordion = $this.closest(".accordion");

            if($content.length < 1){
                $content = $this.closest('.accordion').find(".accordion-content");
            }
            $accordions.removeClass('open')
            $accordionContents.slideUp(speed);


            if(accordionIsOpen($content)){
                $content.slideUp(speed)
                $thisAccordion.removeClass('open');
            }
            else{
                $content.slideDown(speed)
                $thisAccordion.addClass('open');
            }
        })

    })

    function accordionIsOpen($content) {
        return $content.css('display') == 'block'
    }
})