$(document).ready(function() {
    addTabListener();
    initializeTabber();
    tabberResponsive(992);
  });
  
  function tabberResponsive(breakAt) {
    breakAt = breakAt || 768;
    $(window).on("load resize", function() {
      let windowSize = $(window).outerWidth();
      $(".tab-content").each(function() {
          let $this = $(this);
        if (windowSize < breakAt) {
          let $tab = $("[data-content-id='" + $this.attr("id") + "']");
          $tab.after($this);
        } else {
          $(".tab-content-wrapper").append($this);
        }
      });
    });
  }
  
  function addTabListener() {
    $(".tab").on("click", function() {
      let $this = $(this);
      let $content = $("#" + $this.data("content-id"));
  
      $(".tab").removeClass("active");
      $(".tab-content")
        .removeClass("active")
        .hide();
      $this.addClass("active");
      $content.show().addClass("active");
    });
  }
  
  function initializeTabber() {
    $(".accordion:first-of-type .tab").click();
  }
  