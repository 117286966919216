$(document).ready(function() {
    $(".table-responsive").tableResponsive(992);
  });
  
  $.fn.tableResponsive = function(breakAt) {
    let $tables = $(this);
    breakAt = breakAt || 768;
  
    $tables.each(function() {
      let $table = $(this);
  
      $(window).on("load resize", handleResponsiveTable);
  
      function handleResponsiveTable() {
        let mobile = isMobile();
        let taggedMobile = isTaggedMobile();
  
        if (!mobile && taggedMobile) {
          displayDesktopTable();
          removeMobileViewTag();
        } else if (mobile && !taggedMobile) {
          displayMobileViewTables();
            addMobileViewTag();
            openContactTable();

        }
        }

        function openContactTable() {           
            $(".contact.tabber .table-responsive.mobile-view table").addClass("open");
            $(".contact.tabber .table-responsive.mobile-view table tr").addClass("display");
            $(".contact.tabber .table-responsive.mobile-view table .document-trigger").hide();
        }
  
      function displayDesktopTable() {
        $table.append(
          newTable()
            .append(tableHeaders())
            .append(tableBody())
        );
      }
  
      function displayMobileViewTables() {
        displaySingleTablesFromTableRows();
        removeDesktopTable();
      }
  
      function displaySingleTablesFromTableRows() {
        let $tableRows = $table.find("tbody > tr");
        $tableRows.each(function() {
          $table.append(singleTable($(this)));
        });
      }
  
      function removeDesktopTable() {
        $table
          .find("table")
          .first()
          .remove();
      }
  
      function singleTable($dataRow) {
        let $singleTable = newTable();
        let $dataCells = $dataRow.find("td");
        let $tableHeaders = $table.find("thead th");
        let $headers = $tableHeaders.clone();
        let i = 0;
        $dataCells.each(function() {
          let tableData = $(this);
          $singleTable.append(
            newTableRow()
              .append($headers[i])
              .append(tableData)
          );
          i++;
        });

        //method located in resource-documents.js
        attachShowResourceEvent($singleTable);
        return $singleTable;
      }
  
      function tableHeaders() {
        let $headers = $table
          .find("table")
          .first()
          .find("th");
        let $thead = newTableHead();
        $headers.each(function() {
          $thead.append($(this));
        });
        return $thead;
      }
  
      function tableBody() {
        let $tbody = newTableBody();
        let $singleTables = $table.find("table");
  
        $singleTables.each(function() {
          let singleData = $(this).find("tr > td");
          $tbody.append(newTableRow().append(singleData));
          $(this).remove(); //clean up table
        });
        return $tbody;
      }
  
      function newTable() {
        return $("<table>");
      }
  
      function newTableRow() {
        return $("<tr>");
      }
  
      function newTableBody() {
        return $("<tbody>");
      }
  
      function newTableHead() {
        return $("<thead>");
      }
  
      function addMobileViewTag() {
        $table.addClass("mobile-view");
      }
  
      function removeMobileViewTag() {
        $table.removeClass("mobile-view");
      }
  
      function isMobile() {
        return $(window).outerWidth() < breakAt;
      }
  
      function isTaggedMobile() {
        return $table.hasClass("mobile-view");
      }
    });
  };
  