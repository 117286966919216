$(document).ready(function () {
  $(".navbar-toggle").on("click", function () {
    $(".slide-menu-wrapper").toggleClass("open");
  });

  createNavArrow();
  debounceOn("resize", [createNavArrow], 200);
  menuWidth();

  $(window).resize(menuWidth);

  $("#owl-demo").owlCarousel({
    nav: true,
    items: 1,
    dots: true,
    navText: ["<i class=\"fa fa-arrow-left\" aria-hidden=\"true\"></i>", "<i class=\"fa fa-arrow-right\" aria-hidden=\"true\"></i>"]
  });

  function menuWidth() {

    if ($(window).outerWidth() < 992) {
      if ($("#navbar").find($(".login-search")).length < 1) {
        $(".mobile-login-search").hide();
        $("#navbar").append($(".mobile-login-search").html());
        $('.resource-center .nav-tabs li').removeClass('active');
      }
    } else if ($(window).outerWidth() > 1199) {
      $("#navbar").find($(".login-search")).remove();
      $(".mobile-login-search").show();
    } else {
      $("#navbar").find($(".login-search")).remove();

    }

  }

  function getElementWidth(element) {
    return $(element).outerWidth();
  }

  function createNavArrow() {
    $(".dropdown-toggle").each(function () {
      _$this = $(this);
      var borderWidth = getElementWidth(_$this) / 2;
      _$this.find(".arrow").css("border-left-width", borderWidth);
      _$this.find(".arrow").css("border-right-width", borderWidth);

    });
  }


  $.fn.responsiveTable = function () {

    var toggleColumns = function ($table) {
      var selectedControls = [];
      $table.find(".accordian").each(function () {
        selectedControls.push($(this).attr("aria-selected"));
      });

    };
    $(this).each(function () {
      toggleColumns($(this));
    });


    $(this).find(".accordian").click(function () {
      $(this).attr("aria-selected", $(this).attr("aria-selected") !== "true");
      toggleColumns($(this).parents(".row-table"));
    });

  };


  $(".resource-center").find('.row-table').responsiveTable();
  $(".dashboard-resource-center").find('.row-table').responsiveTable();

});


// Events
$('.games-filter .dropdown-container')
  .on('click touch', '.dropdown-button', function () {

    if ($(window).outerWidth() > 991) {
      $('.dropdown-container').find('.game-filter-category-list').hide();
      $('.dropdown-container').find('.dropdown-button').removeClass("active");
      $('.game-filter-categories').find('ul').remove();
      $(this).siblings('.game-filter-category-list').find('ul').clone().appendTo($('.game-filter-categories .container'));
      $(this).addClass("active");
      $('.game-filter-categories').show();
    } else {
      $('.dropdown-container').find('.game-filter-category-list').hide();
      $(this).siblings('.game-filter-category-list').show();
    }
  });
$(document).mouseup(function (e) {
  var container = $(".game-filter-category-list");
  var menu = $('.dropdown-container');
  if ($(window).outerWidth() > 991) {
    container = $(".game-filter-categories");

    menu = $('.dropdown-container');
  }
  if ((!container.is(e.target) && container.has(e.target).length === 0)) {
    container.hide();
    $('.dropdown-button').removeClass('active');
  }
});
$('.game-category-button').on('click touch', function () {
  $(this).toggleClass("open");
});
$('.detail-img-thumbs img').on('click touch', function () {
    $('.detail-product-highlight img').attr('src', ($(this).attr('src'))).attr('alt', ($(this).attr('alt'))).attr('title', ($(this).attr('alt')));

})
$('.resource-center .nav-tabs li a').on('click touch', function (e) {
  if ($(window).outerWidth() <= 991) {
    if ($(this).parent().hasClass('active')) {
      $(this).parent().removeClass('active');
      $('.nav-tabs').find('.tab-pane').remove();
      e.stopPropagation();
    } else {
      var target = $(this).attr('href');
      $('.nav-tabs').find('.tab-pane').remove();
      $('.tab-content').find(target).clone().appendTo($(this).parent());
      $('.nav-tabs .tab-pane').find('.row-table').responsiveTable();
    }
  }
});

$('.contact-listing .nav-tabs li a').on('click touch', function (e) {
  if ($(window).outerWidth() <= 991) {
    if ($(this).parent().hasClass('active')) {
      $(this).parent().removeClass('active');
      $('.nav-tabs').find('.tab-pane').remove();
      e.stopPropagation();
    } else {
      var target = $(this).attr('href');
      $('.nav-tabs').find('.tab-pane').remove();
      $('.tab-content').find(target).clone().appendTo($(this).parent());
      $('.nav-tabs .tab-pane').find('.row-table').responsiveTable();
    }
  }
});