$(document).ready(function () {
    let $inputs = $("input, textarea"); 
    let $selects = $("select");

    checkForValues($inputs);

    $inputs.each(function () {
        let $this = $(this);
        $this.on("keyup", function () {
            ($this.val() == "") ? $this.removeClass("has-value"): addHasValueMarker($this);
        })
    })

    $selects.each(function () {
        let $this = $(this);
        $this.on("change", function () {
            ($this.val() == "") ? $this.removeClass("has-value"): $this.addClass("has-value");
        })

    })

    addRequiredMarkToRecaptcha();

})

function addHasValueMarker($elements) {
    $elements.addClass("has-value");
}

function addRequiredMarkToRecaptcha() {
    $(".recaptcha").find("label").find("span").append(" *")
}

function checkForValues(inputs) {    
    inputs.each(function () {
        let $this = $(this);
        ($this.val() == "") ? $this.removeClass("has-value") : addHasValueMarker($this);
    });
}