$(document).ready(function () {

    if ($('.customer-assignment').length > 0) {
        salesClientController();

        $("#updateUser").click(function () {

            var updatedRelatedCustomers = $("#updatedRelatedCustomers").val();
            var selectedSalesPerson = $("#SelectedSalesPerson").val();

            addLoadingMarker($(".save-button"));

            $.post(updateUserUrl, {
                Id: selectedSalesPerson,
                UpdatedRelatedCustomers: updatedRelatedCustomers
            }, function () {
                removeLoadingMarker($(".save-button"));
            })
        });
    }


});

function removeLoadingMarker($element) {
    setTimeout(function(){
        $element.removeClass("loading");
    },750)
}

function addLoadingMarker($element) {
    $element.addClass("loading");
}

function salesClientController() {
    //Constants are declared in /SitefinityWebApp/Mvc/Views/CustomerAssignment/RelatedCustomers.cshtml


    const allClientsApi = customersUrl;
    const selectedClientsApi = relatedCustomersUrl;
    let sc = {};
    sc.allClients = [];
    sc.selectedClients = [];

    $.get(allClientsApi, function (data, status) {
        sc.allClients = data;
        $.get(selectedClientsApi, function (data, status) {
            sc.selectedClients = data;
            removeSelectedClientsFromAllClients();
            attachKendoListBox();
            removeLoadingMarker($(".customer-assignment"));
        });
    });



    function removeSelectedClientsFromAllClients() {
        for (var i = 0; i < sc.selectedClients.length; i++) {
            var itemId = sc.selectedClients[i].Id;
            sc.allClients = sc.allClients.filter(function (obj) {
                return obj.Id !== itemId;
            });
        }
    }

    function attachKendoListBox() {
        let $listAll = $("#allCustomers");
        let $listSelected = $("#selectedCustomers");

        const listTemplate = "<option>#:Name#</option>";
        const connectWithId = "selectedCustomers";

        $listAll.kendoListBox({
            connectWith: connectWithId,
            dataSource: {
                data: sc.allClients
            },
            template: listTemplate,
            toolbar: {
                tools: ["transferTo", "transferFrom"]
            },
        });

        $listSelected.kendoListBox({
            dataSource: {
                data: sc.selectedClients
            },
            template: listTemplate,
            add: addToSelectedClient,
            remove: removeFromSelectedClient
        });

        function addToSelectedClient(e) {
            var dataItem = e.dataItems[0];
            sc.selectedClients.push(dataItem);
            getSelectedIdsAsString();
            
        }

        function getSelectedIdsAsString() {
            var commaSeparatedList = Array.prototype.map.call(sc.selectedClients, s => s.Id).toString();

            $("#updatedRelatedCustomers").val(commaSeparatedList);

        }

        function removeFromSelectedClient(e) {
            var element = e.sender.select();
            var dataItem = e.sender.dataItem(element);

            

            sc.selectedClients = sc.selectedClients.filter(function (obj) {
                return obj.Id !== dataItem.Id;
            });

            getSelectedIdsAsString();
            
        }
    }
}