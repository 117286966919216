$(document).ready(function () {
    let $videoTriggers = $(".video").find(".trigger");
    $videoTriggers.each(function () {
        $(this).click(function () {
            window.history.replaceState(null, null, "?play=" + $(this).attr('id'));
            console.log($(this).attr('id'));
        });
    });
    filterVideos();
    handleResponsiveVideo();

    function filterVideos() {
        $('.video-gallery .select-wrapper select').change(function () {
            var filterValue = $(this).val();

            var filteredVideos = $(".video-card").not('[data-tags*=' + filterValue + ']');
            var allVideos = $(".video-card");

            if (filterValue == 'all') {
                allVideos.show();
            } else {
                allVideos.show();
                filteredVideos.hide();
            }
        });
    }

    function handleResponsiveVideo() {
        let isMobile = $(window).outerWidth() < 768;
        $videoTriggers.each(function () {
            var $trigger = $(this);
            isMobile ? playVideoEmbeded() : playVideoInLightbox();


            function playVideoEmbeded() {
                $trigger.off("click", attachVideoLightBox);
                $trigger.on("click", attachVideoEmbeded);
            }

            function attachVideoEmbeded() {
                let $iframe = $(this).prev();
                let $videoPlaceholder = $(this).parent(".video-placeholder");
                $iframe[0].src = getVideoUrl("embed");
                $videoPlaceholder.addClass("played");
            }

            function playVideoInLightbox() {
                $trigger.off("click", attachVideoEmbeded);
                $trigger.on("click", attachVideoLightBox);
            }

            function attachVideoLightBox() {
                lity(getVideoUrl("watch"));
            }

            function getVideoUrl(type) {
                let source = $trigger.data("src");
                let videoUrl = {
                    embed: "",
                    watch: ""
                };
                videoUrl.embed = source + "?autoplay=1";
                videoUrl.watch = source.split('embed')[0] + "watch?v=" + source.split('embed')[1].replace("/", "");

                return (type == "embed") ? videoUrl.embed : videoUrl.watch + "?autoplay=1";
            }
        });
        sessionStorage.setItem("loadedVideos", true);
    }

    function checkVideosLoadedRescursive() {
        if (hasLoadedVideos()) {
            playVideoInUrlParams();
        } else {
            setTimeout(() => {
                checkVideosLoadedRescursive();
            }, 300);
        }
    }

    function playVideoInUrlParams() {
        sessionStorage.setItem("playingVideo", true); // to be able to load video when age verification interrupts
        const urlParams = new URLSearchParams(window.location.search);
        const playVideo = urlParams.get('play');
        if (playVideo) {
            console.log("playing video");
            document.getElementById(playVideo).click();
        }
    }

    function hasLoadedVideos() {
        console.log("has loaded videos");
        return JSON.parse(sessionStorage.getItem("loadedVideos"));
    }

    if (!hasLoadedVideos()) {
        checkVideosLoadedRescursive();
    } else {
        playVideoInUrlParams();
    }
})