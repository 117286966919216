
//:: HANDLES IMAGE SOURCE TO BACKGROUND IMAGE :://
var imageAsParentBackground = function () {
    var $img = $("img.set-as-background, .background-image-placeholder img");
    $img.each(function () {
        var $target = $(this).closest(".container").parent();
        $target.addClass("has-background-image");
        var wrapperClass = $('.background-setter-config', $target).data('wrapper-class');
        if (wrapperClass) {
            var wrapperClasses = wrapperClass.split(' ');
            for (var i = 0; i < wrapperClasses.length; i++) {
                $target.addClass(wrapperClasses[i]);
            }
        }
        var src = $(this).attr("src");
        var hPosition = $(this).attr("data-horizontal-position");
        var vPosition = $(this).attr("data-vertical-position");
        var fit = $(this).attr("data-fit");

        $target.css({
            "background-image": "url('" + src + "')",
            "background-position": hPosition + " " + vPosition
        });

        if (fit === "cover" || fit === "contain") {
            $target.css({ "background-size": fit });
            $target.css({ "background-repeat": "no-repeat" });
        }
        else {
            $target.css({ "background-repeat": fit });
        }

        $target.css({ "backgroundColor": setBackgroundOverlay($target) });
        $(this).remove();

    });

    function setBackgroundOverlay($target) {
        var rgb_obj = hexToRgb($('.background-setter-config', $target).data('color'));
        var opacity = $('.background-setter-config', $target).data('opacity');
        var rgba;
        try {
            rgba = "rgba(" + rgb_obj.r + "," + rgb_obj.g + "," + rgb_obj.b + "," + opacity + ")";
        }
        catch (e) {
            rgba = "rgba(0,0,0,0)";//set transparent
            console.log(e.message);
        }
        return rgba;
    }

    function hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }


};

$(document).ready(function () {
    imageAsParentBackground();
});

