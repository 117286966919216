// UNIT TEST LOCATION: /src/tst/specs/gameListing.spec.js

$(document).ready(gameListingController);

function gameListingController() {
    let gc = {};
    let initialized = false;

    const gamesApi = "/api/games-search/games";
    const taxonomiesApi = "/api/games-search/hierarchy-taxa";
    const cabinetsApi = "/api/games-search/cabinets";
    const platformsApi = "/api/games-search/platforms";

    let $selectedFiltersPlaceholder = $(".current-selected").find("ul");
    let $clearAll = $(".clear-all-selected").find("a");
    let $orderBy = $(".sort-by-select").find("select");
    let $searchInput = $(".search-all-games").find("input");
    let $searchButton = $(".search-all-games").find("button");

    if (sessionStorage.getItem("keywords") != undefined) {
        $searchInput.val(JSON.parse(sessionStorage.getItem("keywords")));
    }

    gc.selectedFilters = [];
    gc.taxonomies = null;
    gc.cabinets = null;
    gc.platforms = null;
    gc.games = null;
    gc.gameTypeIds = [];
    gc.gameGroupIds = [];
    gc.keyFeatureIds = [];
    gc.cabinetIds = [];
    gc.platformIds = [];

    gc.init = init;
    gc.getSelectedFiltersFromStorage = getSelectedFiltersFromStorage;
    gc.selectedFiltersAreInStorage = selectedFiltersAreInStorage;
    gc.createBlankSelectedFiltersStorage = createBlankSelectedFiltersStorage;
    gc.getSelectedFilters = getSelectedFilters;
    gc.populateGames = populateGames;
    gc.populateTaxonomies = populateTaxonomies;
    gc.populateCabinets = populateCabinets;
    gc.populatePlatforms = populatePlatforms;
    gc.getFilterUrl = getFilterUrl;
    gc.displayGamesWithKendo = displayGamesWithKendo;
    gc.populateFiltersNavigation = populateFiltersNavigation;
    gc.displaySelectedFilters = displaySelectedFilters;
    gc.displayFiltersDropdown = displayFiltersDropdown;

    gc.init();

    return gc;

    function init() {
        if (!initialized) {
            initialized = true;
            initGameFilterEvents();
            gc.populateGames();
            gc.populateTaxonomies();
            gc.populateCabinets();
            gc.populatePlatforms();
        }

        if (gc.cabinets != null && gc.games != null && gc.taxonomies != null && gc.platforms != null) {
            gc.populateFiltersNavigation();
            gc.displaySelectedFilters();

            $orderBy.on("change", updateSearchResults);
            $searchInput.on("blur", updateSearchResults);
            $searchInput.on("keyup", function (e) {
                if (e.which === 13) {
                    updateSearchResults();
                }
            });
            $searchButton.on("click", gc.updateSearchResults);
            $clearAll.on("click", clearAllFilters);
        } else {
            setTimeout(() => {
                this.init();
            }, 100);
        }
    }

    function updateSearchResults() {
        sessionStorage.setItem("keywords", JSON.stringify($searchInput.val()));
        if (window.location.href.includes("detail")) {
            window.location.href = "/games/game-titles/";
        }

        gc.populateGames();
    }

    function initGameFilterEvents() {
        var filters = document.querySelectorAll(".filter");
        if (filters != null && filters.length > 0) {
            filters.forEach(filter => {
                filter.onclick = function () {
                    sessionStorage.setItem("selectedFilters", JSON.stringify([{
                        "text": filter.innerHTML.trim(),
                        "field": filter.getAttribute("data-field"),
                        "id": filter.getAttribute("data-filter-id")
                    }]));
                    sessionStorage.setItem("keywords", JSON.stringify([]));
                    window.location.href = "/games/game-titles/"
                }
            })
        }
    }

    function populateGames() {
        let url = gc.getFilterUrl($searchInput.val(), $orderBy.val());
        $.get(url, function (data, status) {
            gc.games = data.value;
            onFilterablePage() && gc.displayGamesWithKendo();
        });
    }

    function loadSearchAllGamesPage(searchInput) {
        if (searchInput != null) {
            clearAllFilters();
            sessionStorage.setItem("searchParameters", searchInput.trim());
            window.location.href = "/games/game-titles/";
        }
    }

    function displayGamesWithKendo() {
        let gamesPerPage = 24;

        if (typeof gamesPerPageConfig !== 'undefined') {
            gamesPerPage = gamesPerPageConfig;
        }
        $("#no-results-message").html('');
        let tileTemplate = kendo.template($("#tileTemplate").html());
        var dataSource = new kendo.data.DataSource({
            data: gc.games,
            change: function () {
                $(".product-listing .tiles").html(kendo.render(tileTemplate, this.view()));
            },
            pageSize: gamesPerPage
        });
        dataSource.read();
        $("#pager").kendoPager({
            autoBind: true,
            dataSource: dataSource
        });
        if (dataSource.total() == 0) {
            $("#no-results-message").html('<div class="h2"><strong>No results found.</strong> For information on archived legacy products, please call 1.866.KGI.SLOT. For information on new beta/pilot products, please email sales@konamigaming.com.</div>');
        }
    }

    function populateTaxonomies() {
        $.get(taxonomiesApi, function (data, status) {
            gc.taxonomies = data.value;
        });
    }

    function populateCabinets() {
        $.get(cabinetsApi, function (data, status) {
            gc.cabinets = data.value;
        });
    }

    function populatePlatforms() {
        $.get(platformsApi, function (data, status) {
            gc.platforms = data.value;
        });
    }

    function getFilterUrl(search, orderby) {
        orderby = orderby || "DateCreated desc";
        search = search || "";

        let filters = gc.selectedFilters;
        let orderbyUrl = "$orderby=" + orderby;
        let searchUrl = "contains(Title,'" + search + "')";
        let url;

        if (filters.length > 0) {
            url = gamesApi + "?$filter=";
            for (let i = 0; i < gc.selectedFilters.length; i++) {
                url = (i > 0) ?
                    url + " and " + gc.selectedFilters[i].field + "/any(x:x eq " + gc.selectedFilters[i].id + ")" :
                    url + gc.selectedFilters[i].field + "/any(x:x eq " + gc.selectedFilters[i].id + ")";
            }
            (search == "") ? url = url + "&" + orderbyUrl : url = url + " and " + searchUrl + "&" + orderbyUrl;
        } else {
            url = gamesApi + "?";
            (search == "") ? url = url + orderbyUrl : url = url + "$filter=" + searchUrl + "&" + orderbyUrl;
        }

        return url;
    }

    function selectedFiltersAreInStorage() {
        return sessionStorage.getItem("selectedFilters") != undefined;
    }

    function createBlankSelectedFiltersStorage() {
        sessionStorage.setItem("selectedFilters", JSON.stringify([]));
    }

    function getSelectedFilters() {
        return JSON.parse(sessionStorage.getItem("selectedFilters"));
    }

    function setSelectedFiltersStorageToLocalValue() {
        sessionStorage.setItem("selectedFilters", JSON.stringify(gc.selectedFilters));
    }

    function getSelectedFiltersFromStorage() {
        if (!gc.selectedFiltersAreInStorage()) {
            gc.createBlankSelectedFiltersStorage();
        }
        gc.selectedFilters = gc.getSelectedFilters();
    }

    function displaySelectedFilters() {
        var selectedFilters = JSON.parse(sessionStorage.getItem("selectedFilters"));
        if (selectedFilters != null) {
            for (let i = 0; i < selectedFilters.length; i++) {
                activateFilterById(selectedFilters[i].id, 0);
            }
        }
    }

    function activateFilterById(id, count) {
        var queryString = "[data-filter-id='" + id + "']";
        var elements = document.querySelectorAll(queryString);
        if (elements[0] != null) {
            if (window.location.href.includes("detail")) {
                if (typeof elements[0].addClass !== "undefined") {
                    elements[0].addClass("active");
                }
            } else {
                elements[0].click();
            }
        } else {
            if(count < 5)
                setTimeout(() => { activateFilterById(id, count++); }, 250);
        }
    }

    function populateFiltersNavigation() {
        callFilterService("gametypes", gc.gameTypeIds);
        callFilterService("gamegroups", gc.gameGroupIds);
        callFilterService("keygamesfeatures", gc.keyFeatureIds);
        callFilterService("RelatedCabinetIds", gc.cabinetIds);
        callFilterService("RelatedPlatformIds", gc.platformIds);
    }


    function clearAllFilters() {
        gc.selectedFilters = [];
        setSelectedFiltersStorageToLocalValue()
        $selectedFiltersPlaceholder.html("");
        $("[data-filter-id]").removeClass("active");
    }

    function callFilterService(field, filterArray) {
        $.get(gamesApi, function (data, status) {
            gc.games = data.value;
            populateFiltersDropdown(field, filterArray);
        }).done(function () {
            gc.displaySelectedFilters();
        });
    }

    function populateFiltersDropdown(field, filterArray) {
        for (let i = 0; i < gc.games.length; i++) {
            let dataList = gc.games[i][field];
            for (let x = 0; x < dataList.length; x++) {
                let dataItem = dataList[x];
                if ($.inArray(dataItem, filterArray) == -1) {
                    filterArray.push(dataItem);
                }
            }
        }
        gc.displayFiltersDropdown(field, filterArray);
    }

    function displayFiltersDropdown(field, dataList) {
        let $filterCategory = $("#" + field);
        let id;
        var someList = [];
        for (let i = 0; i < dataList.length; i++) {
            if (!containsObject(dataList[i], someList)) {
                someList.push(dataList[i]);
            }
        }

        for (let i = 0; i < someList.length; i++) {
            id = someList[i];
            let taxonName = getTaxonomyNameById(id, field);
            if (taxonName == null) {
                console.log("Taxon Name: " + taxonName + " not found");
                continue;
            }
            $filterCategory.append(filterListItem(taxonName, id));
            attachSelectedFilterEvent($filterCategory.find($("[data-filter-id='" + id + "']")), field, id);
        }
    }
    function containsObject(obj, list) {
        var x;
        for (x in list) {
            if (list.hasOwnProperty(x) && list[x] === obj) {
                return true;
            }
        }

        return false;
    }
    function getTaxonomyNameById(id, field) {
        let sArray = gc.taxonomies;
        if (field == "RelatedCabinetIds") {
            sArray = gc.cabinets;
        }
        if (field == "RelatedPlatformIds") {
            sArray = gc.platforms;
        }

        var taxonomies = $.grep(sArray, function (taxonomy) {
            return taxonomy.Id == id;
        });
        if (taxonomies.length < 1) {
            console.log("missing " + field + " id: " + id);
        }
        var taxonomy = $.grep(sArray, function (taxonomy) {
            return taxonomy.Id == id;
        })[0];
        if(taxonomy != null)
            return taxonomy.Title;
        return null;
    }

    function attachSelectedFilterEvent($element, field, id) {
        $element.on("click", function () {
            displaySelectedFilter($(this), field, id);
            gc.populateGames();
        });
    }

    function onFilterablePage() {

        let filterablePage = false;

        if (typeof isFilterPage !== 'undefined' && window.location.href.includes("detail") == false) {
            filterablePage = isFilterPage
        }

        return filterablePage;
    }

    function displaySelectedFilter($element, field, id) {
        if (filterNotActive($element)) {
            $element.addClass("active");
            addAsSelectedFilter($element.text(), field, id);
        }
    }

    function filterNotActive($element) {
        return !$element.hasClass("active");
    }

    function addAsSelectedFilter(text, field, id) {
        $selectedFiltersPlaceholder.append(selectedListItem(text, id));
        addToSelectedFilters(text, field, id);
        addClearFilterEvent();
    }

    function addToSelectedFilters(text, field, id) {
        gc.selectedFilters.push({
            "text": text,
            "field": field,
            "id": id
        });
        setSelectedFiltersStorageToLocalValue();
    }

    function addClearFilterEvent() {
        let $clear = $(".clear");
        $clear.on("click", function () {
            let selectedFilterId = $(this).data("selected-id")
            removeFromSelectedFilters(selectedFilterId);
            $(this).parent().remove();
            $("[data-filter-id='" + selectedFilterId + "']").removeClass("active");
            gc.populateGames();
        })
    }

    function removeFromSelectedFilters(filter) {
        gc.selectedFilters = $.grep(gc.selectedFilters, function (e) {
            return e.id != filter;
        });
        setSelectedFiltersStorageToLocalValue();
    }

    function filterListItem(text, id, href) {
        href = href || "javascript:void(0)";
        return $("<li><a href='" + href + "' data-filter-id='" + id + "'>" + text + "</a></li>");
    }

    function selectedListItem(text, id) {
        return $("<li><span>" + text + "<a class='clear' data-selected-id='" + id + "' href='javascript:void(0)'> x </a></span> </li>")
    }
}