$(document).ready(function () {
    denyRequest();
    approveRequest();
});

function approveRequest() {
    $(".account-request .button.approve").click(function () {
        var requestId = $(this).closest(".account-request").data("request-id");
        var salesPerson = $(this).parent().siblings(".salesperson-container").find("select").val();
        var request = $(this).closest(".account-request");
        //approveRequestUrl is set on the AccountRequest Index view
        $.post(approveRequestUrl, {
            responseId: requestId,
            salesPersonId: salesPerson
        }, function () {            
            request.remove();
           
       });
    });
}

function denyRequest() {
    $(".account-request .button.deny").click(function () {
        var requestId = $(this).closest(".account-request").data("request-id");        
        var request = $(this).closest(".account-request");
        //denyRequestUrl is set on the AccountRequest Index view
        $.post(denyRequestUrl, {
            responseId: requestId,            
        }, function () {            
            request.remove();
        });     
    });
}